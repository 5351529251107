






























































































import { Component } from "vue-property-decorator";
import PaymentHeader from "./PaymentHeader.vue";
import { mixins } from "vue-class-component";
import PaymentDocumentsMixin from "@/mixins/PaymentDocumentsMixin";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Actions from "@/components/Actions.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import Table from "@/components/Table.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import PdfView from "@/views/AutomaticPaymentsOverview/PdfView.vue";
import MailButton from "@/components/Button/MailButton.vue";
import { namespace } from "vuex-class";
import AbortButton from "@/components/Button/AbortButton.vue";
import { CLASSIC_PAY, LIVE_PAY, FLASH_PAY } from "@/constants/PaymentWorkflow";
import { TranslateResult } from "vue-i18n";
import CancelInvoiceModal from "@/views/AutomaticPaymentsOverview/CancelInvoiceModal.vue";
import FileXmarkButton from "@/components/Button/FileXmarkButton.vue";
import PaymentDocumentsEmailForm from "@/views/AutomaticPaymentsOverview/PaymentDocumentsEmailForm.vue";
import UserService from "@/services/UserService";

const PaymentDocumentHistoryModule = namespace("payment-document-history");
const AppModule = namespace("app");

@Component({
  components: {
    PaymentDocumentsEmailForm,
    FileXmarkButton,
    CancelInvoiceModal,
    AbortButton,
    MailButton,
    PdfView,
    TextDecimal,
    Table,
    FilterAndSearch,
    PrintButton,
    FileCsvButton,
    Actions,
    FscSimpleCard,
    PaymentHeader,
  },
})
export default class StudentDocumentsHistory extends mixins(PaymentDocumentsMixin) {
  public name = "StudentDocumentsHistory";

  protected tableFields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "date",
      label: this.$t("calendar.form_date"),
    },
    {
      key: "paymentDocumentNumber",
      label: this.$t("general.number"),
    },
    {
      key: "paymentDocumentTypeName",
      label: this.$t("general.type"),
    },
    {
      key: "amountInEur",
      label: this.$t("students.amount"),
    },
    {
      key: "studentDocumentId",
      label: "",
    },
  ];
  protected user: any = this.currentUser || null;

  @PaymentDocumentHistoryModule.Action("findAll")
  public findAllPaymentHistoryDocuments: any;

  @PaymentDocumentHistoryModule.Getter("getDataList")
  public paymentDocumentsHistory: any;

  @PaymentDocumentHistoryModule.Getter("getIsLoading")
  public paymentDocumentsHistoryLoading: any;

  @PaymentDocumentHistoryModule.Mutation("SET_DATA_LIST")
  public paymentDocumentsHistorySetDataList: any;

  @PaymentDocumentHistoryModule.Action("cancelInvoice")
  protected cancelInvoice!: (invoiceForCancellationId: number) => Promise<void>;

  @PaymentDocumentHistoryModule.Getter("getCancelInvoiceSuccess")
  protected getCancelInvoiceSuccess!: boolean;

  @AppModule.Getter("getSelectedAutomaticPaymentStudent")
  protected getSelectedAutomaticPaymentStudent!: any;

  private studentEducationId = 0;

  protected cancelInvoiceModalId = "history-cancel-invoice-modal-id";

  public get currentUser(): any {
    return UserService.getUser();
  }

  public requestQuery(): void {
    //
  }

  private fetchStudents(): void {
    const studentEducationId = this.getSelectedAutomaticPaymentStudent?.studentEducationId;

    if (!studentEducationId) {
      this.paymentDocumentsHistorySetDataList([]);
      return;
    }

    if (this.studentEducationId == studentEducationId) return;

    this.studentEducationId = studentEducationId;
    this.findAllStudents(studentEducationId);
  }

  protected findAllStudents(studentEducationId: number): void {
    this.findAllPaymentHistoryDocuments({
      resource: `payment-documents?studentEducationId=${studentEducationId}`,
    });
  }

  public activated(): void {
    this.fetchStudents();
  }

  private get studentName(): string {
    return this.getSelectedAutomaticPaymentStudent?.studentName;
  }

  private get licenseClass(): string {
    return this.getSelectedAutomaticPaymentStudent?.licenseClass;
  }

  protected get paymentType(): TranslateResult {
    switch (this.getSelectedAutomaticPaymentStudent?.paymentType) {
      case LIVE_PAY:
        return this.$t("automatic_payment.live_pay");
      case FLASH_PAY:
        return this.$t("automatic_payment.flash_pay");
      default:
        return this.$t("automatic_payment.classic_pay");
    }
  }

  protected async okCancelInvoice(): Promise<void> {
    if (!this.selectedIsCancelable) return;
    await this.cancelInvoice(this.selectedRow.paymentDocumentId);
    const studentEducationId = this.getSelectedAutomaticPaymentStudent?.studentEducationId;

    if (this.getCancelInvoiceSuccess && studentEducationId) {
      this.findAllStudents(studentEducationId);
    }
  }
}
